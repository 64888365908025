import React from 'react'
import Img from 'gatsby-image'
import gsap from 'gsap'
import Fade from 'react-reveal/Fade'

import HomeHeroText from '../Home/HomeHeroText'
import ScrollLink from '../Ui/ScrollLink'

class HomeHero extends React.Component {
  state = {
    scrollY: 0,
    maskOpacity: 0,
    textOpacity: 1,
    scale: 2,
    ready: false,
  }

  eventScroll = () => {
    const windowHeight = window.innerHeight
    const scrollY = window.scrollY
    // const scrollYOffset1 = scrollY - windowHeight / 2
    const scrollYOffset2 = scrollY - windowHeight * 1.5
    const buffer = windowHeight / 8

    // image scale..
    if (scrollY < windowHeight) {
      this.setState({
        scale: 2 - (scrollY / windowHeight) * 1,
      })
    } else {
      this.setState({
        scale: 1,
      })
    }

    // text opacity..
    if (scrollY > buffer && scrollY < windowHeight) {
      this.setState({
        textOpacity: 1 - (scrollY / windowHeight) * 1,
      })
    }
    if (scrollY > windowHeight) {
      this.setState({
        textOpacity: 0,
      })
    }
    if (scrollY < buffer) {
      this.setState({
        textOpacity: 1,
      })
    }

    // image movement and mask opacity..
    if (scrollY > windowHeight * 1.5 && scrollY <= windowHeight * 2.5) {
      this.setState({
        scrollY: scrollYOffset2,
        maskOpacity: scrollYOffset2 / ((windowHeight / 2.5) * 2),
      })
    } else if (scrollY <= windowHeight) {
      this.setState({
        scrollY: 0,
        maskOpacity: 0,
      })
    }
  }

  eventTick = () => {
    const { scrollY, maskOpacity, textOpacity, scale } = this.state

    gsap.to('.home-hero__image-scale', {
      duration: 1,
      scale: scale,
    })

    gsap.to('.home-hero__image', {
      duration: 1,
      y: -(scrollY / 4),
      z: -(scrollY / 4),
    })

    gsap.to('.home-hero__title', {
      duration: 1,
      opacity: textOpacity,
    })

    gsap.to('.home-hero__mask', {
      duration: 1,
      opacity: maskOpacity,
    })
  }

  imageLoaded = () => {
    if (this.state.ready === false) {
      this.setState({
        ready: true,
      })
    }
  }

  componentDidMount = () => {
    window.addEventListener('scroll', this.eventScroll)
    gsap.ticker.add(this.eventTick)
  }

  componentWillUnmount = () => {
    window.removeEventListener('scroll', this.eventScroll)
    gsap.ticker.remove(this.eventTick)
  }

  render() {
    return (
      <div className="home-hero">
        <Fade duration={2250} in={this.state.ready}>
          <div className="home-hero__main">
            <ScrollLink
              to="discover"
              text="Discover"
              className="z-40"
              position="left"
            />
            <div className="home-hero__mask z-50"></div>
            <div className="home-hero__title z-30">
              <h1 className="text-4xl md:text-6xl lg:text-9xl font-semibold md:font-medium uppercase tracking-wide leading-none">
                Dedication
              </h1>
              <HomeHeroText />
            </div>
            <div className="home-hero__image z-10">
              <div className="home-hero__image-scale">
                <Img
                  fluid={this.props.image.childImageSharp.fluid}
                  alt="Okains Bay"
                  objectFit="cover"
                  objectPosition="50% 50%"
                  onLoad={this.imageLoaded.bind(this)}
                />
              </div>
            </div>
          </div>
        </Fade>
      </div>
    )
  }
}

export default HomeHero
