import React from 'react'
import Img from 'gatsby-image'
import gsap from 'gsap'

import ArrowLink from '../Ui/ArrowLink'

// import pastImage from '../../images/home/past.jpg'
// import presentImage from '../../images/home/present.jpg'
// import futureImage from '../../images/home/future.jpg'

class OurStory extends React.Component {
  constructor(props) {
    super(props)
    this.container = React.createRef()
  }

  state = {
    length: 3,
    xPos: 0,
    lineAxPos: 0,
    lineBxPos: 0,
    scrollBuffer: 25,
    slideA: false,
    slideB: false,
    slideC: false,
    test: 0,
    animationsStarted: false,
  }

  eventScroll = () => {
    const { length, slideA, slideB, slideC, animationsStarted } = this.state

    const containerRect = this.container.current.getBoundingClientRect()
    const x =
      ((window.scrollY - (containerRect.top + window.scrollY)) /
        (window.outerHeight * length)) *
      100

    // reset the animations if the user returns to the top of the page
    if (window.scrollY < 25 && animationsStarted) {
      this.setState({
        slideA: false,
        slideB: false,
        slideC: false,
        animationsStarted: false,
      })
    }

    // set active slides to trigger animations..
    if (x > -16.666666 && !slideA) {
      this.setState({
        slideA: true,
        animationsStarted: true,
      })
    }

    if (x > 22.222222 && !slideB) {
      this.setState({
        slideB: true,
      })
    }

    if (x > 55.555555 && !slideC) {
      this.setState({
        slideC: true,
      })
    }

    // Move lineA
    if (x > 16.666666 && x < 33.333333) {
      this.setState({
        lineAxPos: (x - 16.666666) * 12,
      })
    } else if (x > 33.333333) {
      this.setState({
        lineAxPos: 200,
      })
    } else {
      this.setState({
        lineAxPos: 0,
      })
    }

    // Move lineB
    if (x > 50 && x < 66.666666) {
      this.setState({
        lineBxPos: (x - 50) * 12,
      })
    } else if (x > 66.666666) {
      this.setState({
        lineBxPos: 200,
      })
    } else {
      this.setState({
        lineBxPos: 0,
      })
    }

    // animate horizontal scroll
    if (containerRect.top >= 0) {
      this.setState({
        xPos: 0,
      })
    } else if (
      containerRect.top < 0 &&
      containerRect.bottom > window.outerHeight
    ) {
      this.setState({
        xPos: x,
      })
    } else if (containerRect.bottom <= window.outerHeight) {
      this.setState({
        xPos: (100 / length) * (length - 1),
      })
    }
  }

  eventTick = () => {
    const { xPos, lineAxPos, lineBxPos } = this.state
    gsap.to('.horizontal-scroll-container', {
      duration: 1,
      x: `-${xPos}%`,
    })
    gsap.to('.line-a', {
      duration: 1,
      x: `${lineAxPos}%`,
    })
    gsap.to('.line-b', {
      duration: 1,
      x: `${lineBxPos}%`,
    })
  }

  componentDidMount() {
    window.addEventListener('scroll', this.eventScroll, false)
    gsap.ticker.add(this.eventTick)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.eventScroll, false)
    gsap.ticker.remove(this.eventTick)
  }

  render() {
    const { length, scrollBuffer, slideA, slideB, slideC } = this.state
    const { images } = this.props
    return (
      <div className="relative w-full">
        <div style={{ height: `${scrollBuffer}vh` }}></div>
        <div ref={this.container} style={{ height: `${100 * length}vh` }}></div>
        <div style={{ height: `${scrollBuffer}vh` }}></div>
        <div
          className="absolute top-0 w-screen"
          style={{
            height: `${100 * length + scrollBuffer * 2}vh`,
          }}
        >
          <div className="sticky top-0 h-screen-calc w-screen overflow-hidden">
            <div
              className="horizontal-scroll-container h-full flex"
              style={{ width: `${100 * length}vw` }}
            >
              <div className="relative w-screen h-full gutters">
                <div className="our-story__line z-20">
                  <div className="our-story__line-inner line-a"></div>
                </div>

                <div className="relative w-full h-full flex items-center justify-center z-10">
                  <div
                    className={`our-story__main relative z-10 ${
                      slideA ? 'active' : ''
                    }`}
                  >
                    <div className="w-full h-full">
                      <Img
                        className="w-full h-full"
                        fluid={images.past.childImageSharp.fluid}
                        alt="Our Story - Past"
                        objectFit="cover"
                        objectPosition="50% 50%"
                      />
                      <div className="our-story__gradient">
                        <div className="our-story__gradient-inner"></div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`our-story__text ${
                      slideA ? 'active' : ''
                    } absolute text-center z-20`}
                  >
                    <h2 className="text-3xl md:text-6xl uppercase font-medium tracking-wide lg:mb-4">
                      past
                    </h2>
                    <ArrowLink
                      link="past"
                      linkText="Discover"
                      textColor="white"
                      arrowDirection="right"
                      arrowColor="white"
                    />
                  </div>
                </div>
              </div>
              <div className="relative w-screen h-full gutters">
                <div className="our-story__line z-20">
                  <div className="our-story__line-inner line-b"></div>
                </div>
                <div className="relative w-full h-full flex items-center justify-center">
                  <div
                    className={`our-story__main relative z-10 ${
                      slideB ? 'active' : ''
                    }`}
                  >
                    <div className="w-full h-full">
                      <Img
                        className="w-full h-full"
                        fluid={images.present.childImageSharp.fluid}
                        alt="Our Story - Present"
                        objectFit="cover"
                        objectPosition="50% 50%"
                      />
                      <div className="our-story__gradient">
                        <div className="our-story__gradient-inner"></div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`our-story__text ${
                      slideB ? 'active' : ''
                    } absolute text-center z-20`}
                  >
                    <h2 className="text-3xl md:text-6xl uppercase font-medium tracking-wide lg:mb-4">
                      present
                    </h2>
                    <ArrowLink
                      link="present"
                      linkText="Discover"
                      textColor="white"
                      arrowDirection="right"
                      arrowColor="white"
                    />
                  </div>
                </div>
              </div>
              <div className="relative w-screen h-full gutters">
                <div className="relative w-full h-full flex items-center justify-center z-10">
                  <div
                    className={`our-story__main relative z-10 ${
                      slideC ? 'active' : ''
                    }`}
                  >
                    <div className="w-full h-full">
                      <Img
                        className="w-full h-full"
                        fluid={images.future.childImageSharp.fluid}
                        alt="Our Story - Future"
                        objectFit="cover"
                        objectPosition="50% 50%"
                      />
                      <div className="our-story__gradient">
                        <div className="our-story__gradient-inner"></div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`our-story__text ${
                      slideC ? 'active' : ''
                    } absolute text-center z-20`}
                  >
                    <h2 className="text-3xl md:text-6xl uppercase font-medium tracking-wide lg:mb-4">
                      future
                    </h2>
                    <ArrowLink
                      link="past"
                      linkText="Future"
                      textColor="white"
                      arrowDirection="right"
                      arrowColor="white"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default OurStory
