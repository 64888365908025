import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import Fade from 'react-reveal/Fade'
import { BrowserView, MobileView } from 'react-device-detect'

import Layout from '../components/Layout'
import SEO from '../components/SEO'
import Footer from '../components/Footer'
import ScrollIndicator from '../components/Ui/ScrollIndicator'
import Hero from '../components/Hero'
import HomeHero from '../components/Home/HomeHero'
import HomeOurStory from '../components/Home/HomeOurStory'
import Tracking from '../components/ContentBlocks/ContentBlocksTracking'
import ArrowLink from '../components/Ui/ArrowLink'

const footerLinks = ['the-kawatea', 'pot-fishing', 'past']

class IndexPage extends React.Component {
  state = {
    checkForWindow: false,
  }
  componentDidMount() {
    this.setState({
      checkForWindow: typeof window !== 'undefined',
    })
  }
  render() {
    const { location, data } = this.props
    const { checkForWindow } = this.state
    const page = data.prismicHome.data
    return (
      <Layout notInMenu>
        <SEO
          title={page.meta_title}
          description={page.meta_description}
          location={location}
        />
        <ScrollIndicator />
        {checkForWindow && (
          <>
            <BrowserView>
              <HomeHero image={data.heroImage} />
            </BrowserView>
            <MobileView>
              <Hero
                title="Dedication"
                isHomeHero
                image={data.heroImage.childImageSharp.fluid}
              />
            </MobileView>
          </>
        )}
        <div id="discover">
          <HomeOurStory
            images={{
              past: data.pastImage,
              present: data.presentImage,
              future: data.futureImage,
            }}
          />
          <div className="mb-40">
            <Tracking />
          </div>
          <div className="gutters mb-40">
            <Fade delay={225} duration={2250}>
              <div className="flex flex-col md:flex-row">
                <div className="order-2 md:order-1 md:w-5/12 lg:w-1/3 flex items-center justify-center">
                  <div className="text-center uppercase leading-none">
                    <div className="type-upper-xs mb-2 md:mb-4">Our Vessel</div>
                    <h4 className="tracking-wider text-2xl md:text-3xl font-semibold mb-2 lg:mb-4">
                      Kawatea
                    </h4>
                    <ArrowLink
                      link="the-kawatea"
                      linkText="Discover"
                      arrowDirection="right"
                    />
                  </div>
                </div>
                <div className="order-1 md:order-2 md:w-7/12 lg:w-2/3 flex">
                  <div className="w-full lg:w-15/16 mb-10 md:mb-0">
                    <Img
                      className="sm:hidden"
                      fluid={data.kawateaMobile.childImageSharp.fluid}
                    />
                    <Img
                      className="hidden sm:block lg:hidden"
                      fluid={data.kawateaTablet.childImageSharp.fluid}
                    />
                    <Img
                      className="hidden lg:block"
                      fluid={data.kawateaDesktop.childImageSharp.fluid}
                    />
                  </div>
                  <div className="lg:w-1/16"></div>
                </div>
              </div>
            </Fade>
          </div>
          <div className="relative">
            <Fade delay={225} duration={2250}>
              <div className="absolute lg:top-35 inset-x-0 lg:inset-x-auto lg:right-0 z-10 mt-20 lg:mt-0 lg:mr-24">
                <div className="text-center uppercase leading-none">
                  <div className="type-upper-xs mb-2 md:mb-4">
                    Dedication to
                  </div>
                  <h4 className="tracking-wider text-2xl md:text-3xl font-semibold mb-2 lg:mb-4">
                    Sustainability
                  </h4>
                  <ArrowLink
                    link="the-kawatea"
                    linkText="Discover"
                    arrowDirection="right"
                  />
                </div>
              </div>
              <div>
                <Img
                  className="sm:hidden"
                  fluid={data.sustainabilityMobile.childImageSharp.fluid}
                />
                <Img
                  className="hidden sm:block lg:hidden"
                  fluid={data.sustainabilityTablet.childImageSharp.fluid}
                />
                <Img
                  className="hidden lg:block"
                  fluid={data.sustainabilityDesktop.childImageSharp.fluid}
                />
              </div>
            </Fade>
          </div>
        </div>
        <Footer links={footerLinks} />
      </Layout>
    )
  }
}

IndexPage.propTypes = {
  location: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
}

export const pageHomeQuery = graphql`
  query {
    heroImage: file(relativePath: { eq: "hero/home.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    pastImage: file(relativePath: { eq: "home/past.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1024) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    presentImage: file(relativePath: { eq: "home/present.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1024) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    futureImage: file(relativePath: { eq: "home/future.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1024) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    sustainabilityDesktop: file(
      relativePath: { eq: "home/sustainability-desktop.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 2390, maxHeight: 1352) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    sustainabilityTablet: file(
      relativePath: { eq: "home/sustainability-tablet.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1524, maxHeight: 1512) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    sustainabilityMobile: file(
      relativePath: { eq: "home/sustainability-mobile.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 654, maxHeight: 1054, cropFocus: SOUTH) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    kawateaDesktop: file(relativePath: { eq: "home/kawatea.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1684, maxHeight: 1130) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    kawateaTablet: file(relativePath: { eq: "home/kawatea.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 814, maxHeight: 1058) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    kawateaMobile: file(relativePath: { eq: "home/kawatea.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 580, maxHeight: 682) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    prismicHome {
      data {
        meta_title
        meta_description
      }
    }
  }
`

export default IndexPage
